<template>
  <div class="assetStandard">
    <div class="assetStandard-content">
      <!-- 表格数据 -->
      <v-list
        ref="list"
        :headers="headers"
        :tableUrl="getAssetSourceData"
        :isHeadBtn="false"
      >
        <template #searchSlot>
          <v-input label="资产名称："></v-input>
        </template>
        <template #searchBtnAfterSlot>
          <v-button text="新增" @click="handelClickAdd"></v-button>
        </template>
        <template #operateSlot="{ row }">
          <v-button type="text" text="编辑" @click="editData(row)"></v-button>
          <v-button
            type="text"
            text="删除"
            v-if="row.modelNames.length == 0"
            @click="deleteData(row)"
          ></v-button>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getAssetSourceData,removeAssetData } from "./api.js";
export default {
  name: "assetStandard",
  components: {},
  props: {},
  data() {
    return {
      getAssetSourceData,
      headers: [
        {
          prop: "name",
          label: "资产名称",
        },
        {
          prop: "modelNames",
          label: "关联模型",
          formatter: (row, prop) => {
            let result;
            if (row[prop].length > 0) {
              result = row[prop].join("、");
            } else {
              result = "无";
            }
            return result;
          },
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    //编辑
    editData(row) {
      this.$router.push({
        name: "assetStandardForm",
        query: {
          id: row.id,
        },
      });
    },
    //新增
    handelClickAdd() {
      this.$router.push({
        name: "assetStandardForm",
      });
    },
    //删除数据
    deleteData(row) {
      this.$confirm("是否确认删除?", "提示", {
        cancelButtonText: "取消",
        confirmButtonText: "删除",
        type: "warning",
      }).then(() => {
        const params = {
          ids: row.id,
        };
        this.$axios.post(removeAssetData, params).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.$refs.list.getList();
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.assetStandard {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-right: 0 !important ;
  .assetStandard-content {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 35px;
    box-sizing: border-box;
    .section-search {
      display: flex;
      justify-content: space-between;
      .search-l {
        display: flex;
        .search-input {
          margin-right: 20px;
        }
      }
    }
    .section {
      &:first-child {
        margin-bottom: 20px;
      }
      .title {
        font-weight: 600;
        font-size: 18px;
        color: #000;
        margin-bottom: 20px;
      }
      .table {
        .section-circle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 0.1rem;
          .circle-button {
            font-size: 0.24rem;
          }
        }
      }
    }
  }
}
</style>
